import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import lorry from '../images/lorry.webp';
import './Login.css';
const Login = () => {
  return (
    <div className="container p-4" style={{ backgroundImage: `url(${lorry})`,height:'auto' ,maxWidth:'100%', backgroundSize: 'cover' }}>
    <Container fluid className="p-4" style={{ height: '100%' }}> 
    <Row className="justify-content-center align-items-center">
    <Col md={6} className="mb-4">
          <div className="contact-content p-3" style={{ color: 'white', backgroundColor: 'rgba(60, 70, 90, 0.6)', borderRadius: '8px' }}>
            <h1 className="text-center mb-4" style={{ backgroundColor: '', color: 'white', padding: '10px', borderRadius: '8px' }}>Contact</h1>
            <p>If you have any questions regarding our privacy policy or wish to report a breach, please contact us using the information on the support page.</p>
            <h3 className="mb-3" style={{ color: 'white' }}>Grievance Officer</h3>
            <p>In accordance with the Information Technology Act, 2000 (including amendments thereto) and rules made thereunder, the contact details of the Grievance Officer are provided below for queries or concerns regarding this privacy policy:</p>
            <p><b>Email Id:</b> help@primemoovers.com</p>
            <p><b>Time:</b> Monday to Friday (10 a.m. to 6 p.m.)</p>
            <p>For any queries or concerns regarding our services, kindly email us at help@primemoovers.com.</p>
          </div>
        </Col>
        </Row>
        </Container>
        </div>
  )
}

export default Login