import React from 'react';
import { Container } from 'react-bootstrap';
import lorry from '../images/lorry.webp';
import './Company.css';
const Company = () => {
  return (
    <div className="company_page">

        {/* style={{backgroundImage: `url(${lorry})`,height:'100vh',backgroundSize:'cover'}}  
        1<Container className="d-flex flex-column justify-content-center align-items-center text-center z-index position-relative" >
          <div >
          <h1>Welcome to PRIME MOVERS</h1>
          <p>Prime Moovers: Your Swift and Reliable Cargo Partner
          </p>
          <button className="btn btn-primary">Learn More</button>
          </div>
          
        </Container> */}

<Container >
    <div class="p-4 ">
       
    <h1 className='text-center'style={{color:'blue'}}> PRIME MOOVERS </h1>
    <div className='mt-4'>

<h3  style={{color:'blue'}}>Prime Moovers: Your Swift and Reliable Cargo Partner</h3>
<p><b><big>Prime Moovers</big></b> is a cutting-edge digital platform revolutionizing the cargo transportation industry in India. Our innovative bidding system empowers customers to book cargo transport with unparalleled speed and efficiency.
</p>
<h3 style={{color:'blue'}}>Key Services:</h3>
<ul>
  <li><b>Real-time Booking:</b> Secure cargo transportation within 180 minutes through our user-friendly platform.</li>
<li><b>Flexible Capacity: </b> Transport goods ranging from 100 kilograms to 100 tonnes to accommodate diverse needs.</li>
<li><b>Competitive Pricing: </b> Benefit from transparent pricing through our bidding system, ensuring cost-effective solutions.
<li><b>Reliable Network: </b> Access a vast network of verified transporters for secure and timely deliveries.</li>
<li><b>End-to-End Visibility: </b> Track your cargo's journey in real-time for peace of mind.</li>
</li>
</ul>
<p>By combining advanced technology with a deep understanding of logistics, Prime Moovers is committed to providing exceptional service and delivering cargo safely and on time. Whether you're an individual or a business, experience the future of cargo transportation with Prime Moovers.</p>

<h3 style={{color:'blue'}}>The Prime Moovers Difference:
</h3>
<p>What truly sets Prime Moovers apart is their pioneering <b>bidding system</b> for goods transportation. This innovative platform allows customers to compare quotes from multiple transporters, ensuring competitive pricing and transparency. By disrupting the traditional logistics model, Prime Moovers empowers customers to make informed decisions and achieve significant cost savings.
</p>
<p>With a strong emphasis on customer satisfaction, Prime Moovers has garnered a reputation for excellence in the industry. Their team of skilled professionals and advanced logistics infrastructure ensure seamless and efficient relocation experiences.
</p>

<h3 style={{color:'blue'}}>Contact Prime Moovers:
</h3>
<ul>
  <li><b>Email: </b>  For any queries or concerns regarding our services, kindly email us at care@primemoovers.com. Our email support hours are Monday to Friday (10 a.m. to 6 p.m.).</li>
<li><b>Phone: </b> For further details, you can also contact Rajesh K at 8050530909. Please note that phone support timings may vary.</li>
</ul>
<p>We recommend using email for general inquiries and using the phone number for more urgent matters.
</p>
<h3 style={{color:'blue'}}>Ready to Get Started?
</h3>
<p>Visit the Prime Moovers website or contact us through the information provided to get a quote and experience the Prime Moovers difference!
</p>
    </div>
    </div>
    </Container>   
      </div>
  );
}

export default Company;
