import React from 'react'
import lorry from '../images/lorry.webp';
const TermsofService = () => {
  return (
    <div class="container " style={{backgroundColor: 'white' }}>
    <div class="p-4 ">
       
    <h1 className='text-center'style={{color:'blue'}}> Terms of Service </h1>
    <div className='mt-4'>
    <p> 
In the event there is a conflict between the terms and conditions specified herein and the provisions of any other document executed between the parties hereto, the terms and conditions specified herein would prevail.
 </p>
 <h2 className='text-center'style={{color:'blue'}}>DEFINITIONS</h2>
 <p>The following definitions apply to the terms and conditions set out below that govern this contract of Carriage between you and us:
 </p>
<ul>  
<li> "We", "us", "our", "Carrier", "Prime Moovers", "Company" shall refer to PRIME MOOVERS  (owner of Prime Moovers), its employees, and its service providers that carry or undertake to carry the consignment hereunder or perform any other services incidental thereto on its behalf.
</li>
<li>  “You”, “your”, “consignor” shall refer to the sender, consignor or consignee of the Consignment, holder of this Consignment Note, receiver and owner of the contents of the Consignment or any other party having a legal interest in those contents, as the case may be.
</li>
<li> the services of goods transport of vehicles by Customers for a point to point service, or for time and usage based service within city limits and outside the city limits, including inter-city all over India;
</li>
<li>“Services” means the services provided by Prime Moovers which enable the following:
</li>
<li>hiring of labour by Customers for loading/unloading of the consignment; and
</li>
<li> such other services which Prime Moovers may from time to time add.
</li>
<li>"Carriage" means and includes the whole of the operations and services undertaken by us in connection with the Consignment.
</li>
<li> "Consignment" means any package, parcel, sachet, or freight which is or are given to and accepted by us for carriage under our Consignment Note.
</li>
<li>“Dangerous Goods” means goods classified as dangerous as per ICAO T.I., IATA DGR, IMDG-Code, ADR or other national regulations for transport.
</li>
<li>“Delivery” means the tender of the consignment to the consignee or intimation about the arrival of the consignment.
</li>
<li> "Prohibited Items" means any goods or materials, the Carriage of which is prohibited by Applicable Law.
</li>
<li>“Receiver” or “Consignee” shall refer to the recipient or addressee or the consignee of the Consignment.
</li>
<li>“Applicable Law” means means all laws, statutes, ordinance, regulations, guidelines, policies, rules, bye-laws, notifications, directions, directives and orders or other governmental restrictions or any similar form of decision of, or determination by, or any interpretation, administration and other pronouncements having the effect of law of the Republic of India or any other applicable jurisdiction by state, municipality, court, tribunal, government, ministry, department, commission, arbitrator or board or such other body which has the force of law in India.
</li>

</ul>
<h2 style={{color:'blue'}}> USER(S) ELIGIBLITY</h2>
<p>User(s) means any individual or business entity/ organization that legally operates in India or in other country, uses and has the right to use the Services provided by Prime Moovers. It is hereby being clarified that our Services are available only to those individuals or business entities/organizations who can form legally binding contracts under the Applicable Law. To be a User(s) and be eligible to use our Services, an individual must be at least 18 (eighteen) years of age.
</p>
<p>Prime Moovers advises its users that while accessing the web site, they must follow/abide by the related laws. Prime Moovers is not responsible for the possible consequences caused by your behavior / acts during use of web site. Prime Moovers may, in its sole discretion, refuse the service to anyone at any time.
</p>
<h2 style={{color:'blue'}}>USER(S) AGREEMENT
</h2>
<p>This agreement applies to user(s) if user(s) are visitors, registered - free or paid user(s) who access the web site for any purpose. It also applies to any legal entity which may be represented by you under actual or apparent authority. User(s) may use this site solely for their own personal or internal purposes.
</p>
<p>This agreement applies to all Prime Moovers Services, collectively with any additional terms and condition that may be applicable to the specific service used/accessed by user(s).In the event of a conflict or inconsistency between any provision of the terms and conditions mentioned herein with those of the particular service, the provisions of the terms and conditions applicable to such specific Services shall prevail.
</p>
<h2 style={{color:'blue'}}>AMENDMENT TO USER(S) AGREEMENT
</h2>
<p>Prime Moovers may change, modify, amend, or update this agreement from time to time with or without any prior notification to user(s) and the amended and restated terms and conditions of use shall be effective immediately on posting. If you do not adhere to the changes, you must stop using the service. Your continuous use of the Services will signify your acceptance of the changed terms and deemed acceptance of the amended Agreement. Further, User(s) shall also be bound by any amendment made in any policy or agreement from time to time, referred to in these Terms of Service.
</p>
<h2 style={{color:'blue'}}>INTELLECTUAL PROPERTY RIGHTS
</h2>
<p>`Prime Moovers is the sole owner or lawful licensee of all the rights to the web site and its content. Web site content means its design, layout, text, images, graphics, sound, video etc. The web site content embody trade secrets and intellectual property rights protected under worldwide copyright and other laws. All title, ownership and intellectual property rights in the web site and its content shall remain with Prime Moovers, its affiliates or licensor's of Prime Moovers content, as the case may be.
</p>
<p>All rights, not otherwise claimed under this agreement or by Prime Moovers.in, are hereby reserved. The information contained in this web site is intended, solely to provide general information for the personal use of the reader, who accepts full responsibility for its use. Prime Moovers does not represent or endorse the accuracy or reliability of any information, or advertisements (collectively, the "content") contained on, distributed through, or linked, downloaded or accessed from any of the Services contained on this web site, or the quality of any products, information or other materials displayed, or obtained by you as a result of an advertisement or any other information or offer in or in connection with the Service. We accept no responsibility for any errors or omissions, or for the results obtained from the use of this information. All information in this web site is provided "AS IS " with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. Nothing herein shall to any extent substitute for the independent investigations and the sound technical and business judgment of the user(s). In no event shall Prime Moovers be liable for any direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever with respect to the Service. User(s) of this site must hereby acknowledge that any reliance upon any content shall be at their sole risk.
</p>
<p>Prime Moovers reserves the right, in its sole discretion and without any obligation, to make improvements to, or correct any error or omissions in any portion of the Service or the app/website. Trademark
</p>
<p>All related icons and logos are registered trademarks or trademarks or service marks of Prime Moovers in various jurisdictions and are protected under applicable copyright, trademark and other proprietary rights laws. The unauthorized copying, modification, use or publication of these marks is strictly prohibited. Copyright
</p>
<p>All content on this web site is the copyright of Prime Moovers except the third party content and link to third party web sites on our app/website.
</p>
<p>Systematic retrieval of Prime Moovers content to create or compile, directly or indirectly, a collection, compilation, database or directory (whether through robots, spiders, automatic devices or manual processes) without written permission from Prime Moovers is prohibited.
</p>
<p>In addition, use of the content for any purpose not expressly permitted in this Agreement is prohibited and may invite legal action. As a condition of your access to and use of Prime Moovers's Services, you agree that you will not use the web site service to infringe the intellectual property rights of Prime Moovers or any other third party in any way. Prime Moovers reserves the right to terminate the account of a user(s) upon any infringement of the rights of any third party in conjunction with use of the Prime Moovers service, or if Prime Moovers believes that user(s) conduct is harmful to the interests of Prime Moovers, its affiliates, or other users, or for any other reason in Prime Moovers's sole discretion, with or without cause. You shall be liable to indemnify Prime Moovers for any losses or expenses incurred by Prime Moovers due to any infringement of intellectual property rights owned by Prime Moovers without prejudicing Prime Moovers's right to bring any legal action against you.
</p>
<h2 style={{color:'blue'}}>LINKS TO THIRD PARTY SITES
</h2>
<p>Links to third party sites are provided by web site as a convenience to user(s) and Prime Moovers has no control over such sites i.e content and resources provided by them.
</p>
<p>Prime Moovers may allow user(s) access to content, products or Services offered by third parties through hyperlinks (in the form of word link, banners, channels or otherwise) to such Third Party's web site. You are cautioned to read such sites' terms and conditions and/or privacy policies before using such sites in order to be aware of the terms and conditions of your use of such sites. Prime Moovers believes that user(s) acknowledge that Prime Moovers has no control over such third party's site, does not monitor such sites, and Prime Moovers shall not be responsible or liable to anyone for such third party site, or any content, products or Services made available on such a site. User(s) shall review Prime Moovers's Privacy Policy and abide by Prime Moovers's Privacy Policy at the time of the User(s) interaction with Prime Moovers, with respect to and concerning any information and data.
</p>
<h2 style={{color:'blue'}}>TERMINATION
</h2>
<p>Most content and some of the features on the web site are made available to visitors free of charge. However, Prime Moovers reserves the right to terminate access to certain areas or features of the web site at any time for any reason, with or without notice. Prime Moovers also reserves the universal right to deny access to particular users to any/all of its Services without any prior notice/explanation in order to protect the interests of Prime Moovers and/or other visitors to the web site. Prime Moovers reserves the right to limit, deny or create different access to the web site and its features with respect to different user(s), or to change any of the features or introduce new features without prior notice.
</p>


</div>
    </div>
   
   </div>
  )
}

export default TermsofService