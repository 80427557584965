// LandingPage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import './Home.css'; // Import your custom CSS for styling
import gif3 from '../images/gif3.gif';
const Home = () => {
  return (
    <>

    <div className="landing-page">
      <div className="overlay">
 <Container className="d-flex flex-column justify-content-center align-items-center text-center z-index position-relative">
       <div>
        <h1>Welcome to PRIME MOVERS </h1>
       
        <p>Discover amazing features and services we offer.</p>  
        <button className="btn btn-primary">Learn More</button>
      
        </div>
        {/* <img src={gif3} alt='gif' className='gif' style={{borderRadius:'100%',marginBottom:'100px',maxHeight:'180px',}}/> */}
        
      </Container>  
      
      
      
        </div> </div>
      

    </>
  );
}

export default Home;



// // LandingPage.js
// import React from 'react';
// import { Container } from 'react-bootstrap';
// import './LandingPage.css'; // Import your custom CSS for styling

// const LandingPage = () => {
//   return (
//     <div className="landing-page">
//       <div className="overlay"></div>
//       <Container className="d-flex flex-column justify-content-center align-items-center text-center">
//         <h1>Welcome to Our Website</h1>
//         <p>Discover amazing features and services we offer.</p>
//         <button className="btn btn-primary">Learn More</button>
//       </Container>
//     </div>
//   );
// }

// export default LandingPage;
