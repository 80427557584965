// import React from 'react'
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import logo from '../images/logo.jpeg'
// const Header = () => {
//   return (
    
//     <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary ">
//       <Container style={{backgroundColor:'#9ba5b8' ,maxWidth:'100%' ,height:'70px'}}>
//         <Navbar.Brand className='' href="/"><img src={logo} className='img-fluid' alt='logo'style={{marginLeft:'48px', alignItems:'center',maxWidth:'70px' , borderRadius:'50px'}}/></Navbar.Brand>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//         
/* <Nav className="me-auto">
<NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
  <NavDropdown.Divider />
  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
</NavDropdown>
</Nav> */
//        
//           <Nav className=""  style={{marginRight:'60px'}} >
//           <Nav.Link href="/company" style={{ color:'#153661'}}>COMPANY</Nav.Link>
//             <Nav.Link href="/products" style={{color:'#153661'}}>PRODUCTS</Nav.Link>
//             <Nav.Link href="/careers" style={{color:'#153661'}}>CAREERS</Nav.Link>
//             <Nav.Link href="/contact" style={{color:'#153661'}}>CONTACT</Nav.Link>
//             <Nav.Link href="/login" style={{color:'#153661'}}>LOGIN</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
 

//   )
// }

// export default Header;

import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../images/logo.jpeg';
import giff from '../images/giff.gif';
const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary  border-right" id="sidebar-wrapper" style={{ backgroundColor: '#f9ca08' }}>
      <Container style={{ backgroundColor: '#3bb0e2' ,maxWidth:'100%'}}>
        <Navbar.Brand href="/">
          <img src={logo} className="img-fluid" alt="logo" style={{marginLeft:'48px', maxWidth: '70px', borderRadius: '50px' }} />
        </Navbar.Brand>
        <Navbar.Brand href="/">
          <img src={giff} className="img-fluid" alt="logo" style={{marginLeft:'48px', maxWidth: '70px', borderRadius: '50px' }} />
        </Navbar.Brand>
        {/* <img src={gif3} alt='gif' className='gif' style={{borderRadius:'100%',marginBottom:'100px',maxHeight:'180px',}}/> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav bg-white" style={{ backgroundColor:'white'}} />
        <Navbar.Collapse id="responsive-navbar-nav bg-white"style={{ color:'white'}} >
          <Nav className="me-auto">
            
          </Nav>
          <Nav>
          <b><Nav.Link href="/company" style={{ color: 'white' }}>COMPANY</Nav.Link></b>
          <b> <Nav.Link href="/products" style={{ color: 'white' }}>PRODUCTS</Nav.Link></b>
          <b> <Nav.Link href="/team" style={{ color: 'white' }}>TEAM</Nav.Link></b>
         
          <b><Nav.Link href="/contact" style={{ color: 'white' }}>CONTACT</Nav.Link></b>
            <b><Nav.Link href="https://prime.primemoovers.com/" style={{ color: 'white' }}>LOGIN</Nav.Link></b>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;


// Sidebar.js

// import React from 'react';
// import { Nav } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

// const Header = () => {
//   return (
//     <div className="bg-light border-right" id="sidebar-wrapper">
//       <div className="sidebar-heading">Sidebar</div>
//       <Nav className="flex-column pt-2">
//         <Nav.Link as={Link} to="/" className="text-dark">
//           Home
//         </Nav.Link>
//         <Nav.Link as={Link} to="/about" className="text-dark">
//           About
//         </Nav.Link>
//         <Nav.Link as={Link} to="/services" className="text-dark">
//           Services
//         </Nav.Link>
//         <Nav.Link as={Link} to="/contact" className="text-dark">
//           Contact
//         </Nav.Link>
//       </Nav>
//     </div>
//   );
// }

// export default Header;
