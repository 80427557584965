import React from 'react'

const RefundPolicy = () => {
  return (
    <div class="container ">
    <div class="p-4 ">
    <h1 className='text-center'style={{color:'blue'}}>  Refund Policy
    </h1>
    <div className='mt-4'>
    <h3 style={{color:'blue'}}> Eligibility for Refunds </h3>
<ul>
    <li>Users are eligible for a refund if the cancellation is made as per the above-mentioned 
    cancellation policy</li>
    <li>In case of any discrepancies or issues with the service, users can file a complaint through our 
    customer support or Raise Ticket option within [Within 24 hours] of the incident </li>
    </ul>
    <h3 style={{color:'blue'}}>Refund Process</h3>
    <ul>
    <li>Refunds will be processed within 24 hours after the cancellation request is approved.
    </li>
    <li>No refund will be issued for no-shows or if the user fails to board the vehicle at the 
    designated time and location.   </li>
</ul>
<h3 style={{color:'blue'}}> Modification Policy</h3>
<ul><li>Users can modify their booking (e.g., change in pickup time or location) up to [specific time, 
e.g., 1 hour] before the scheduled pickup time, subject to availability and additional charges 
if applicable.
</li></ul>
<h3 style={{color:'blue'}}>  Exceptional Circumstances</h3>
<ul>
<li>In case of unavoidable circumstances (e.g., natural disasters, strikes), the cancellation fee 
may be waived, and full refunds will be provided at the company’s discretion.</li></ul>
<h3 style={{color:'blue'}}>  Contact Information</h3>
<ul>
<li>For any queries or assistance regarding cancellations and refunds, please contact our customer 
support team at contact@primemoovers.com</li></ul>
        </div>
        </div>
        </div>
  )
}

export default RefundPolicy