import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Careers from './Components/Careers/Careers';
import Company from './Components/Company/Company';
import Contact from './Components/Contact/Contact';
import Products from './Components/Products/Products';
import Login from './Components/Login/Login';
import Team from './Components/Team/Team';
import Disclaimer from './Components/Footer/Disclaimer';
import TermsAndConditions from './Components/Footer/TermsAndConditions';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy';
import TermsofService from './Components/Footer/TermsofService';
import CancellationPolicy from './Components/Footer/CancellationPolicy';
import RefundPolicy from './Components/Footer/RefundPolicy';

function App() {
  return (
    <>
    <Header/>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={< Home/>}/>
     
      <Route path='/company' element={<Company/>}/>
      <Route path='/products' element={<Products/>}/>
      <Route path='team' element={<Team/>}/>
     
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/disclaimer' element={<Disclaimer/>}/>
      <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
      <Route path='/terms-of-service' element={<TermsofService/>}/>
      <Route path='/cancellationPolicy' element={<CancellationPolicy />}/>
      <Route path='/refundPolicy' element={< RefundPolicy/>}/>

    </Routes>




    </BrowserRouter>

    <Footer/>
    </>
  );
}

export default App;
