import React, { useState, useEffect } from 'react';
import lorry from '../images/lorry.webp';
import { Container, Row, Col } from 'react-bootstrap';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    message: '',
    agree: false,
  });

  // Load formData from localStorage on component mount
  useEffect(() => {
    const storedFormData = localStorage.getItem('formData');
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
    // Store form data in localStorage
    localStorage.setItem('formData', JSON.stringify(formData));
    // Example: You can send formData to an API endpoint or perform validation

    // Clear form after submission (optional)
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      message: '',
      agree: false,
    });
  };

  return (
    <div className="container p-4" style={{ backgroundImage: `url(${lorry})`,height:'auto' ,maxWidth:'100%', backgroundSize: 'cover' }}>
      <Container fluid className="p-4" style={{ height: '100%' }}> 
      <Row className="justify-content-center align-items-center">
      <Col md={6} className="mb-4">
            <div className="contact-content p-3" style={{ color: 'white', backgroundColor: 'rgba(60, 70, 90, 0.6)', borderRadius: '8px' }}>
              <h1 className="text-center mb-4" style={{ backgroundColor: '', color: 'white', padding: '10px', borderRadius: '8px' }}>Contact</h1>
              <p>If you have any questions regarding our privacy policy or wish to report a breach, please contact us using the information on the support page.</p>
              <h3 className="mb-3" style={{ color: 'white' }}>Grievance Officer</h3>
              <p>In accordance with the Information Technology Act, 2000 (including amendments thereto) and rules made thereunder, the contact details of the Grievance Officer are provided below for queries or concerns regarding this privacy policy:</p>
              <p><b>Email Id:</b> help@primemoovers.com</p>
              <p><b>Phone: </b> For further details, you can also contact Rajesh K at 8050530909. Please note that phone support timings may vary.</p>
              <p><b>Time:</b> Monday to Friday (10 a.m. to 6 p.m.)</p>
              <p>For any queries or concerns regarding our services, kindly email us at help@primemoovers.com.</p>
            </div>
          </Col>
    
          <Col md={6}>
            <div className="container" style={{ backgroundColor: 'rgba(60, 70, 90, 0.6)', color: 'white' }}>
              <h2>Contact to PRIMEMOVERS</h2>
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="firstName"><b>First Name:</b></label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName"><b>Last Name:</b></label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email"><b>Email:</b></label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mobileNumber"><b>Mobile Number:</b></label>
                  <input
                    type="tel"
                    id="mobileNumber"
                    name="mobileNumber"
                    className="form-control"
                    pattern="[0-9]{10}"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                  />
                  <small className="form-text text-muted"><b style={{ color: 'white' }}>Format: 10 digits (e.g., 1234567890)</b></small>
                </div>
                <div className="form-group">
                  <label htmlFor="message"><b>Your Message:</b></label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="form-control"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    className="form-check-input"
                    checked={formData.agree}
                    onChange={handleChange}
                    required
                  />
                  <label className="form-check-label" htmlFor="agree">
                    I agree to the terms and conditions by PRIME MOVERS for additional information, periodic promotions,
                    appointment reminders, and to the Terms & Conditions. SMS data rates may apply. Opt out anytime by
                    replying “STOP”.
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </Col>
         
        </Row>
       </Container> 
    </div>
  );
}

export default Contact;
