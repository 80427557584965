import React from 'react'

const Disclaimer = () => {
  return (
    
    <div class="container bg-white">
    <div class="p-4 ">
       
    <h1 className='text-center'style={{color:'blue'}}> DISCLAIMER </h1>
    <div className='mt-4'>
    <p>All vehicles registered with the Company are continuously tracked using mobile technology for security reasons only. It is hereby made expressly clear to you that the Company does not own any vehicle nor it directly or indirectly employ any driver for the vehicles or Labour for loading/unloading of Consignment. Vehicles, drivers and Labour are all supplied by third parties and the Company disclaims any and all liability(ies) in respect of the Labour, drivers and the vehicles alike.
    The Company have right to use the customer contact information for its own marketing purposes. The Company may send regular SMS updates to the mobile numbers registered with it.
    </p>
    <p>
    The information contained in this website and Prime Moovers App is for general information purposes only. The information is provided by Prime Moovers and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website / App or the information, products, services, or related graphics contained on the website / App for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website / App.
Through this website / App you are able to link to other websites which are not under the control of Prime Moovers. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
Every effort is made to keep the portal up and running smoothly. However, Prime Moovers takes no responsibility for, and will not be liable for, the portal being temporarily unavailable due to technical issues beyond our control.

    </p>
    </div>
    </div>
    </div>
    

  )
}

export default Disclaimer;