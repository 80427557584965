import React from 'react'

const TermsAndConditions = () => {
  return (
    <div class="container ">
    <div class="p-4 ">
       
    <h1 className='text-center'style={{color:'blue'}}> Terms&Conditions For Use Of Our Service </h1>
    <div className='mt-4'>
        <p>The following Terms & Conditions shall apply to customers utilising the Services offered by the Company for the hiring of vehicles:
             The customer shall pay the fare (as agreed), parking charges, additional night surcharge (where applicable) and any fee or levy presently payable or hereinafter imposed by the Applicable Law or required to be paid for availing the Services of Prime Moovers.
              The customer agrees and accepts that the use of the Services provided by the Company is at the sole risk of the Customer, and further acknowledges that the Company disclaims all representations and warranties of any kind, whether express or implied. 
              The customer shall ensure that he/she will not indulge in any of the following activities while availing the service: </p>
              <ul>
                <li>Soiling or damaging the body and/or any other interiors of the vehicles.</li>
                <li>Misusing, soiling or damaging any of the devices (technical/non-technical) in the vehicle.</li>
                <li>Asking the driver to break any Traffic/RTO/City Police and/or government rules for any purpose. The driver has the right to refuse such a request by the customer. The driver also has the right to refuse such a pick-up.
                </li>
              
                <li>Pressurizing the driver to overload truck with the consignment than the allowed limit.</li>
                </ul>
            <p> The Customer shall indemnify Company from and against and in respect of any or all liabilities, losses, charges and expenses (including legal fees and costs on a full indemnity basis) claims, demands, actions and proceedings which Company may incur or sustain directly or indirectly from or by any reason of or in relation to the use or proposed use of the Services by the Customer and shall pay such sums on demand on the Company </p>
            <p>The Company is hereby authorized to use the location based information provided by any of the telecommunication companies when the Customer uses the mobile phone to make a vehicle booking. The location based information will be used only to facilitate and improve the probability of locating a mini-truck for the Customer</p>
<p>The Company shall be entitled to disclose to all companies within its group, or any government body as so required by the Applicable Law or by directive or request from any government body, the particulars of the Customer in the possession of Company in any way as Company, in its absolute discretion, deems fit or if it considers it in its interests to do so. </p>
<p>The Company shall be entitled at any time without giving any reason to terminate the booking of the vehicle done by the Customer. User(s) shall indemnify Prime Moovers with respect to any expenses incurred with respect to such booking </p>
<p>Prime Moovers shall be liable for the loss or damage of the consignment to the extent permissible by Carriage by Road Act and Rules. </p>
<p>In the event of loss of any item, User(s) shall not have any right to withhold the payment to be made to Prime Moovers. Further, in the event any payments from the User to Prime Moovers are pending for the period prescribed by Prime Moovers in its respective invoice / statement, Prime Moovers reserves the right, in accordance with the Applicable Law, to exercise particular lien over the consignment till full payment is made to Prime Moovers for the Services. Additionally, User(s) will be liable to indemnify Prime Moovers against any loss, damage or expenses incurred by it due to the custody of the consignment during this period.</p>
<p>Any complaint in respect of the Services or the use of the vehicles, the Customer has to inform the Company of the same in writing within 24 hours of using the vehicles or Services of the Company.</p>
<p>The Company shall not be liable for any conduct of the drivers of the vehicles. However, the Company encourages you to notify it, of any complaints that you may have against the driver that you may have hired using the Company's Services.</p>
<p>The Company shall be entitled to add to, vary or amend any or all these terms and conditions at any time and the Customer shall be bound by such addition, variation or amendment once such addition, variation or amendment are incorporated into these terms and conditions at Company's website at www.primemoovers.com on the date that Company may indicate that such addition, variation or amendment is to come into effect.</p>
<p>
All the calls made to the Company's call centre are recorded by the Company for quality and training purposes. In the event you place a query on our app/website including any query with respect to our Services, applicable fees or Terms of Service, you hereby expressly agree to consent to receive our responses, whether by way of telephonic calls or electronic mail, to such query and all related information with respect to our Services. For removal of doubts, related information shall include without limitation any marketing and/or commercial information. You understand, agree and acknowledge that such information shall in no event, qualify as unsolicited commercial communication under the Telecom Unsolicited Commercial Communications Regulations, 2007 and/or due to disclosure of such information, our telephone numbers shall not qualify to be registered under the 'National Do Not Call Register' or the 'Private Do Not Call Register' in accordance with the Telecom Unsolicited Commercial Communications Regulations, 2007 or any other Applicable Law.</p>
<p>The Company will consider the email address and / or mobile number utilized by the user for transacting with Prime Moovers, for seeking the user’s information required for processing of payments, for any reason whatsoever. The User hereby acknowledges that Prime Moovers explicitly disclaims any responsibilities or liabilities resulting from inaccuracies in the data or information provided by the user.  </p>
<p> Cancellation Policy: All cancellations made 5 minutes after driver allocation will incur a cancellation fee of INR. 50/- (Indian Rupees Fifty only). Cancelling four bookings in a day after driver allocation will temporarily suspend your account for 24 hours. Figures are subject to change. </p>
<p>Toll Charges: In case of a toll on your trip, return toll fare will be charged.  </p>
<h2 style={{color:'blue'}}>LABOUR SERVICES
</h2>
<p>`Prime Moovers provides the option to Customers to utilize Labour Services by clicking on the “Include Labour” toggle on the review screen of the website/App.
</p>
<p>“Labour Services” means that the service provided by the labour wherein labour undertakes the task of loading/unloading the consignment of goods from the loading/unloading point to the vehicle or vice-versa, as the case may be.
</p>
<p>“Labour” means the driver of the vehicle and the person accompanying him in the vehicle.
</p>
<p>By clicking on the “Include Labour” toggle on the review screen of the Website/App or by availing the Labour Services, the Customer agrees to the following terms and conditions. These terms shall be applicable in addition to the terms and conditions applicable to the customers for hiring of vehicles:
</p>    
<ul>
<li>You acknowledge that the Labour Service is provided only for ground floor to ground floor loading/unloading. You acknowledge that the ground floor movement (distance between the transport vehicle and the loading/unloading point) shall not exceed 25 metres.
</li>
<li>You acknowledge that the extra charges (as visible in the Invoice) for the Labour Service shall be payable by you.
</li>
<li>You agree that you shall be present in person at the time of loading/unloading of Consignment. If you are not available, an authorised representative must be present.
</li>
<li>You agree that Prime Moovers shall not be liable for misbehaviour of the labour during the loading/unloading. However, you are requested to bring to the notice of Company such incidents. The Company, in its sole discretion, may take action in such matters.
</li>

</ul>
<h2 style={{color:'blue'}}>CONFIDENTIALITY
</h2>
<p>User(s) shall not disclose any information received under the contract of service with Prime Moovers to any third party. Access to any information which pertains to business of Prime Moovers shall be kept confidential to the extent it might adversely impact Prime Moovers's business. User(s) shall be liable to indemnify Prime Moovers against any loss of business or reputation due to the act of the user(s).
</p>
<h2 style={{color:'blue'}}>DISCLAIMER
</h2>
<p>All vehicles registered with the Company are continuously tracked using mobile technology for security reasons only. It is hereby made expressly clear to you that the Company does not own any vehicle nor it directly or indirectly employ any driver for the vehicles or Labour for loading/unloading of Consignment. Vehicles, drivers and Labour are all supplied by third parties and the Company disclaims any and all liability(ies) in respect of the Labour, drivers and the vehicles alike.
</p>
<p>The Company have right to use the customer contact information for its own marketing purposes. The Company may send regular SMS updates to the mobile numbers registered with it.
</p>
<h2 style={{color:'blue'}}>INSURANCE INFO
</h2>
<p>Please find Insurance related terms and conditions here Please find Courier related terms and conditions here Please find Prime Moovers wallet related FAQs and terms & conditions here
</p>
<h1 style={{color:'blue',alignItems:'center'}}>YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS
</h1>

<p>By giving us your consignment you accept our terms and conditions set out in the consignment note and/or the contract of carriage and/or the contract for the performance of other services on behalf of yourself and/or anyone else who has an interest in the consignment or the performance of other Services irrespective of whether you have signed the front of our consignment note or not. Our terms and conditions also cover and can be invoked by anyone we use or sub-contract to collect, transport, deliver your consignment or perform other Services as well as our employees, directors and agents. Only one of our authorised officers may agree to a variation of these terms and conditions in writing. When you give us the consignment with oral or written instructions that conflict with our terms and conditions we shall not be bound by such instructions.
</p>








    </div>
    </div>
    </div>
  )
}

export default TermsAndConditions