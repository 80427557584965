import React from 'react'
import { Container } from 'react-bootstrap'
import lorry from '../images/lorry.webp';
const Team = () => {
  return (
    <div className="company_page" style={{backgroundImage: `url(${lorry})`,height:'100vh',backgroundSize:'cover'}} >

      
    <Container className="d-flex flex-column justify-content-center align-items-center text-center">
      <h1>Welcome to PRIME MOVERS</h1>
      <p>Discover amazing features and services we offer.</p>
      <button className="btn btn-primary">Learn More</button>
    </Container>
  </div>
  )
}

export default Team