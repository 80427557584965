import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div class="container ">
    <div class="p-4 ">
       
    <h1 className='text-center'style={{color:'blue'}}> PrivacyPolicy </h1>
    <div className='mt-4'>
        <p>This privacy policy sets out how Prime Moovers uses and protects any information that you give Prime Moovers when you use this website/mobile application for Customers and Driver Partners. This Privacy Policy is an electronic record between you and Prime Moovers under the Information Technology Act, 2000 together with the Rules framed from time to time.</p>
        <p>Your privacy matters to SmartShift Logistics Solutions Private Limited (the "Company", "we", "Prime Moovers", or "us").
        </p>
        <p>This Privacy Policy ("Policy") describes the policies and procedures on the collection, use, disclosure and protection of your information when you use our website located at https://Prime Moovers.in/, or the PRIME MOOVERS mobile applications named Prime Moovers and Prime Moovers Delivery Driver App (collectively, "Prime Moovers Apps") and is applicable, without limitation, to those including customers/service recipients and drivers.
        </p>
        <p>The terms "you" and "your" refer to the user of the Prime Moovers Apps. The term "Services" refers to any services offered by Prime Moovers/You whether on the Prime Moovers Apps or otherwise. This Policy is a part of and incorporated within, and is to be read along with, the applicable Terms of Service and the Driver Partner Terms and Conditions based on the user of Prime Moovers.
        </p>
        <p>Prime Moovers is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.
        </p>
        <p>By using the Prime Moovers Apps and the Services, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information as described and collected by us in accordance with this Policy. If you do not agree with the Policy, please do not use or access the Prime Moovers Apps.
        </p>
        <p>Prime Moovers may change this policy from time to time by updating this page. The current version of this policy is effective from 9th March, 2023. You should check this page from time to time to ensure that you are happy with any changes. The first version of this policy was effective from 23rd February 2014, which can be accessed here. The second version of this policy was effective from 11th November, 2022, which can be accessed here.
        </p>
        <h3 style={{color:'blue'}}> WHAT WE COLLECT  </h3>
        <p>We may collect the following information:</p>
<ul>  
    <li>Name and job title. </li>
    <li>Contact information including email address.
    </li>
    <li>Demographic information such as postcode, address, mobile number, login name, preferences and interests.
    </li>
    <li>Use Our Services, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information, invoices, etc.
    </li>
    <li>Other information relevant to customer surveys and/or offers.
    </li>
    <li> App install, uninstall and other installed apps information.
    </li>
    <li>If you sign up to use our Services as a Driver Partner, we may collect location details, profile picture, copies of government identification documents and other details (KYC), vehicle related documents such as insurance, pollution certificate, bank account details, utility bills, registration certificate, driving license, etc. and such other documents which evidence the health or fitness of the vehicle to provide Services on the Prime Moovers Apps, call and SMS details which may be shared with our customer while providing the Services.
    </li>
    <li>We collect background check and identity verification information of the Driver Partner. This may include information such as driver history or criminal record (where permitted by law), and right to work. This information may be collected by an authorized vendor on the Company’s behalf.
    </li>
    <li> In case you sign up as a Driver Partner, enable features that require Prime Moovers’s access to other applications on Your device aiming to prevent frauds towards the customers. In case if the Driver Partner wishes to participate in the referral program, the contact list stored in the phone will be accessed by us post the Driver Partner consents to share such selected mobile number. We do not intend to share such selected mobile numbers with any third party.
    </li>

    <li>You may request when you would like to review the information you have provided and Prime Moovers shall ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient shall be corrected or amended as feasible.
    </li>

    <li>Prime Moovers shall not retain sensitive personal data or information for longer than is required for the purposes for which the information may lawfully be used.
    </li>
</ul>
<h4 style={{color:'blue'}}>WHAT WE DO WITH THE INFORMATION WE GATHER</h4>
      <p> We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
        </p>
        <ul> <li><h5> Internal Record Keeping</h5></li></ul>
        <p>We may use the information to improve our Services. We may use this information to:
        </p>
        <ul>
            <li>Allow you to use Services on the Prime Moovers Apps and to carry out our obligations arising from any contracts between you and us
            </li>
            <li>Allow you to undertake or initiate any transactions on the Prime Moovers Apps
            </li>
            <li>To inform your usage of the Prime Moovers Apps and to manage your account with Prime Moovers Apps
            </li>
            <li>To process the payments with respect to the transactions which you may avail on the Prime Moovers Apps and to perform ancillary Services
            </li>
            <li>To respond to your comments. reviews and questions, in order to provide customer support and better Services
            </li>
            <li>To communicate important notices and changes to the Services provided on the Prime Moovers Apps
            </li>
            <li>To track the order status, processing, and delivery of Services as per your applicability
            </li>
            <li>For any other purposes with your consent.
            </li>
            </ul>
        <p>We may periodically send promotional emails, SMSs and make voice calls about new products, special offers or other information which we think you may find interesting using the email address and mobile number which you have provided.
        </p>
        <p> From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, SMS, voice, fax or mail. We may use the information to customize the website according to your interests.
        </p>
        <p>You may specifically opt-out of the above in accordance with the Telecom Commercial Communications Customer Preference Regulations, 2018 or otherwise specified. In case you wish to opt out of the same, please contact us at 4410 4410 (add your city code).
        </p>

   
   
   
    </div>
    </div>
    </div>
  )
}

export default PrivacyPolicy