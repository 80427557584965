import React from 'react';
import { Container } from 'react-bootstrap';
import lorry from '../images/lorry.webp';
import './Products.css';
const Products = () => {
  return (
    <>
   <div className="product" style={{backgroundImage: `url(${lorry})`,height:'100vh',backgroundSize:'cover'}} >

      
      <Container className="d-flex flex-column justify-content-center align-items-center text-center  z-index position-relative">
        <div>
        <h1>Welcome to PRIME MOVERS</h1>
        <p>Discover amazing features and services we offer.</p>
        <button className="btn btn-primary">Learn More</button>
        </div>
      </Container>
    </div>
   
    </>
  )
}

export default Products