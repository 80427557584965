// import React from 'react'
// import logo from '../images/logo.jpeg';
// import './footer.css';
// const Footer = () => {
//   return (
   
    
// <footer>
//     <div className="container" style={{overflow:'hidden',bottom:0,position:'fixed' ,backgroundColor:'#9ba5b8' ,maxWidth:'100%' ,height:'200px'}}>
//     <img src={logo} className='logo-lg logo-sm' style={{ alignItems:'center',maxWidth:'80px' , borderRadius:'50px'}}/>
 
//     <p className="copyright" style={{display:'inline-block',padding:'10px 20px',fontSize:'20px' ,color:'#153661' }}>© PRIME MOVERS</p>
//   <ul className="foolinks text-center" >
//     <li><a href="/company" style={{textDecoration:'none', color:'#153661'}}>COMPANY</a></li>
//     <li><a href="/products" style={{textDecoration:'none', color:'#153661'}}>TEAM</a></li>
//     <li><a href="/team" style={{textDecoration:'none', color:'#153661'}}>CANCELATION POLICE </a></li>
//     <li><a href="/careers" style={{textDecoration:'none', color:'#153661'}}>REFUND</a></li>
//     <li><a href="/investors" style={{textDecoration:'none', color:'#153661'}}>PRIVATE POLICE</a></li>
//     <li><a href="/contact" style={{textDecoration:'none', color:'#153661'}}>CONTACT</a></li>
//     <li><a href="/terms-and-conditions" style={{textDecoration:'none', color:'#153661'}}>TERMS &amp; CONDITION</a></li>
//     <li><a href="/privacy-policy" style={{textDecoration:'none', color:'#153661'}}>PRIVACY POLICY</a></li>

//     <p className=' m-3 text-center'>&copy; {new Date().getFullYear()}eMedha Technologies. All Rights Reserved.</p>
//   </ul>
  
 
//   </div>

//     </footer>
//   )
//   }
  
//   export default Footer
  
import React from 'react';
import logo from '../images/logo.jpeg';
import './footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import playstor1 from '../images/playstor1.png'
const Footer = () => {
  return (
    <footer style={{  }}>
      <Container fluid className="footer-container " style={{backgroundColor: '#3bb0e2',overflow:'hidden',bottom:0 }}>
        <Row className="align-items-center">
          <Col lg={2} sm={12} className="text-center">
            <img src={logo} alt="Logo" className="img-fluid logo" style={{ alignItems:'center',maxWidth:'105px' , borderRadius:'50px'}}/>
            <div lg={2} sm={12} className="text-center">
            <p className="copyright" style={{ fontSize: '17px' }}>
              &copy; { new Date().getFullYear()} PRIME MOVERS
            </p>
          </div>
          </Col>
          <Col  lg={2} sm={12} className="text-center">

          <a href='https://play.google.com ' target='_blank'><img src={playstor1} alt='playstor-img'  className="img-fluid " style={{height:'130px' ,marginBottom:'30px'}}/></a>
          <div lg={2} sm={12} className="text-center">
          {/* <p className="copyright" style={{ fontSize: '17px' }}>Google Playstor</p> */}
          </div>

          </Col>
          
          
          <Col  lg={8} sm={10} >
            <ul className="foolinks text-center">
              <li><a href="/company" className='text' style={{ textDecoration: 'none' }}>COMPANY</a></li>
              <li><a href="/team"className='text' style={{ textDecoration: 'none' }}>TEAM</a></li>
              <li><a href="/cancellationPolicy" style={{ textDecoration: 'none' }}>CANCELATION POLICY</a></li>
              <li><a href="/refundPolicy" style={{ textDecoration: 'none' }}>REFUND POLICY</a></li>
              <li><a href="/disclaimer" style={{ textDecoration: 'none' }}>DISCLAIMER</a></li>
              <li><a href="/contact" style={{ textDecoration: 'none' }}>CONTACT</a></li>
              <li><a href="/terms-and-conditions" style={{ textDecoration: 'none' }}>TERMS AND CONDITION</a></li>
              <li>< a href="/privacy-policy" style={{ textDecoration: 'none' }}>PRIVACY POLICY</a></li>
              <li><a href="/terms-of-service" style={{ textDecoration: 'none' }}>TERMS OF SERVICE</a></li>           
            </ul>
            <p className=' m-3 bg-white text-center'>&copy; {new Date().getFullYear()} eMedha Technologies. All Rights Reserved.</p>
          </Col>
          
        
        </Row>    
   

    
      </Container>
    </footer>
  );
}

export default Footer;
