import React from 'react'

const CancellationPolicy = () => {
  return (
    <div class="container ">
    <div class="p-4 ">
    <h1 className='text-center'style={{color:'blue'}}> Cancellation Policy </h1>
    <div className='mt-4'>
    <h3 style={{color:'blue'}}>   User-Initiated Cancellations </h3>
<ul>
    <li><b>Before Bid Time:</b> Users can cancel their booking before they post the requirement for 
    bidding</li>
    <li><b>During Bid Time :</b> Users advised to avoid the cancellation during the LIVE bid of 180 mins. 
    No penalty upto 2 cancellations upon this nature. </li>
    <li><b>Before Pickup Time &</b> After Bid Finalization: Users will lose 2,000/- from the token amount 
paid for the truck confirmation if they cancel after the bid finalization and before the truck 
arrival.
    </li>
    <li><b>Before Loading and After Truck arrival at Pickup Point: </b> Users will lose 2,000/- + Travel 
expenses (40Rs/ KM for two way travel) from the Truck starting point and Users pick up 
point. More than 2 cancellations upon this nature will lead to blacklisting of such User 
    </li>
</ul>
<h3 style={{color:'blue'}}> Service Provider-Initiated Cancellations</h3>
<ul><li>In the event that the service provider cancels the booking, the user will be notified 
immediately, and a full refund will be issued.
</li>
<li>In case of Truck driver cancels after the Bid finalization, Users will be allowed to skip to next 
lowest bid and proceed with bid process. </li></ul>
        </div>
        </div>
        </div>
  )
}

export default CancellationPolicy